import axiosInstance from '../AxiosClient'
import {
  createApi,
  createCollectionManager,
  createCustomServiceCall,
  Pagination,
} from '@thinknimble/tn-models'
import {
  workedHoursShape,
  updatedWorkedHourShape,
  customRetrieveWorkedHourInputShape,
  rangeRetrieveInputShape,
  customRetrieveAllWorkedHourInputShape,
  rangeRetrieveAllInputShape,
  canvassingOrgAdminChartInputShape,
} from './models'

const DAILY_WORKED_HOURS_ENDPOINT = '/daily-work-hours/'

const customRetrieve = createCustomServiceCall(
  {
    inputShape: customRetrieveWorkedHourInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { campaign, date } = toApi(input)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        campaign,
        date,
      },
    })
    return response.data
  },
)

const customRetrieveAll = createCustomServiceCall(
  {
    inputShape: customRetrieveAllWorkedHourInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { canvassing_org, date } = toApi(input)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        canvassing_org,
        date,
      },
    })
    return response.data
  },
)

const rangeRetrieve = createCustomServiceCall(
  {
    inputShape: rangeRetrieveInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date, campaign } = toApi(input)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        start_date,
        end_date,
        campaign,
      },
    })
    return response.data
  },
)

const rangeRetrieveAll = createCustomServiceCall(
  {
    inputShape: rangeRetrieveAllInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date, canvassing_org } = toApi(input)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        start_date,
        end_date,
        canvassing_org,
      },
    })
    return response.data
  },
)

const customUpdate = createCustomServiceCall(
  {
    inputShape: updatedWorkedHourShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { id, ...rest } = toApi(input)
    await client.patch(`${DAILY_WORKED_HOURS_ENDPOINT}${id}/`, rest)
  },
)

const shifts_spent_per_campaign = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(
      `${DAILY_WORKED_HOURS_ENDPOINT}shifts_spent_per_campaign/${params}`,
    )
    return res.data
  },
)

export const WorkedHoursApi = createApi(
  {
    baseUri: DAILY_WORKED_HOURS_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: workedHoursShape,
    },
  },
  {
    customUpdate,
    customRetrieve,
    rangeRetrieve,
    customRetrieveAll,
    rangeRetrieveAll,
    shifts_spent_per_campaign,
  },
)

export const workedHourFunctions = () => {
  const workedHourCollection = createCollectionManager({
    fetchList: WorkedHoursApi.list,
    pagination: new Pagination(),
  })

  return {
    workedHourCollection,
  }
}
