<template>
  <DashboardContainer :pageTitle="pageTitle">
    <!-- Spinner for loading state -->
    <Spinner v-if="loading" class="mt-24" />

    <template v-else>
      <!-- Date Inputs -->
      <div class="flex flex-wrap gap-4 mb-6">
        <div class="flex items-center gap-2 flex-grow">
          <label for="start" class="text-gray-700">Start Date:</label>
          <FlatPickr
            id="start"
            name="start"
            class="input--text--small placeholder:text-gray-400 date-input flex-grow"
            placeholder="Select date start"
            :value="selectedDate.date1"
            @input="handleDateChange('date1', $event.target.value)"
          />
        </div>
        <div class="flex items-center gap-2 flex-grow">
          <label for="end" class="text-gray-700">End Date:</label>
          <FlatPickr
            id="end"
            name="end"
            class="input--text--small placeholder:text-gray-400 date-input flex-grow"
            placeholder="Select date end"
            :value="selectedDate.date2"
            @input="handleDateChange('date2', $event.target.value)"
          />
        </div>
      </div>

      <!-- Charts Section for Canvassing Org Admin -->
      <template v-if="isCanvassingOrgAdmin">
        <div class="flex justify-between items-center mb-4">
          <h1 style="font-size: 1.5rem; font-weight: bold; text-decoration: underline">
            Campaign Metrics
          </h1>

          <RouterLink :to="{ name: 'FundraiserPerformanceCharts' }" class="btn--table">
            Fundraiser's Performance Graphs
          </RouterLink>
        </div>
        <h2 class="text-xl font-bold mb-4">Recurring & Success Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Number of Donations Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Number of Donations</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="numberOfDonationsData"
                  :options="chartOptions"
                  v-if="numberOfDonationsData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Donors Over 30 Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Donors Over 30</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="donorsOver30Data"
                  :options="chartOptions"
                  v-if="donorsOver30Data.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Average Donor Age Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Average Donor Age</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="averageDonorAgeData"
                  :options="chartOptions"
                  v-if="averageDonorAgeData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Average Donation Amount Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Average Donation Amount</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="averageDonationAmountData"
                  :options="chartOptions"
                  v-if="averageDonationAmountData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Number of Donors by Time Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">
              Number of Donors by {{ numberOfDonorsByTimeData.x_axis_label }}
            </h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="numberOfDonorsByTimeData"
                  :options="chartOptions"
                  v-if="numberOfDonorsByTimeData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Donor Age Pie Chart -->
          <div class="pie-chart-container">
            <h3 class="title font-bold mb-4">Donor Age Distribution</h3>
            <div class="chart-outer-wrapper">
              <PieChart
                :chart-data="donorAgePieChartData"
                :options="pieChartOptions"
                v-if="donorAgePieChartData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>

          <!-- Donation Amount Pie Chart -->
          <div class="pie-chart-container">
            <h3 class="title font-bold mb-4">Donation Amount Distribution</h3>
            <div class="chart-outer-wrapper">
              <PieChart
                :chart-data="donationAmountPieChartData"
                :options="pieChartOptions"
                v-if="donationAmountPieChartData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <!-- Campaign Metrics Section -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div
            v-for="campaign in campaignMetrics"
            :key="campaign.campaign_id"
            class="bg-white p-6 rounded-lg shadow-md"
          >
            <h3 class="text-xl font-bold mb-4">
              {{ campaign.campaign_name }} - {{ campaign.campaign_location }}
            </h3>
            <ul class="list-disc list-inside space-y-2">
              <li><strong>Number of Fundraisers:</strong> {{ campaign.fundraiser_count }}</li>
              <li><strong>Total Shift Count:</strong> {{ campaign.total_shifts }}</li>
              <li><strong>Number of Donations:</strong> {{ campaign.number_of_donations }}</li>
              <li><strong>Per Shift Average:</strong> {{ campaign.per_shift_average }}</li>
            </ul>
          </div>
        </div>

        <h2 class="text-xl font-bold mt-4 mb-4">One-Time & Success Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Number of One-Time Donations</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="numberOfOneTimeDonationsData"
                  :options="chartOptions"
                  v-if="numberOfOneTimeDonationsData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Average One-Time Donation Amount</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="averageOneTimeDonationAmountData"
                  :options="chartOptions"
                  v-if="averageOneTimeDonationAmountData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Total One-Time Donation Sum</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="totalOneTimeDonationSumData"
                  :options="chartOptions"
                  v-if="totalOneTimeDonationSumData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-xl font-bold mt-4 mb-4">Shifts Count</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Shifts</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="shiftsSpentData"
                  :options="chartOptions"
                  v-if="shiftsSpentData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-xl font-bold mt-4 mb-4">Failed Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Failed Donations by Campaign</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="failedDonationsBarChartData"
                  :options="chartOptions"
                  v-if="failedDonationsBarChartData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Charts Section for Fundraiser -->
      <template v-if="isFundraiser">
        <h2 class="text-xl font-bold mt-4 mb-4">Recurring Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Number of Recurring Donations Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">
              Number of Recurring Donations by {{ timeGrouping }}
            </h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="recurringDonationsCountData"
                  :options="chartOptions"
                  v-if="recurringDonationsCountData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Donors Over 30 Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Donors Over 30 by {{ timeGrouping }}</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="fundraiserDonorsOver30Data"
                  :options="chartOptions"
                  v-if="fundraiserDonorsOver30Data.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add these pie charts for fundraiser -->
        <h2 class="text-xl font-bold mt-8 mb-4">Donor Demographics</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Donor Age Pie Chart -->
          <div class="pie-chart-container">
            <h3 class="title font-bold mb-4">Donor Age Distribution</h3>
            <div class="chart-outer-wrapper">
              <PieChart
                :chart-data="fundraiserAgePieChartData"
                :options="pieChartOptions"
                v-if="fundraiserAgePieChartData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>

          <!-- Donation Amount Pie Chart -->
          <div class="pie-chart-container">
            <h3 class="title font-bold mb-4">Donation Amount Distribution</h3>
            <div class="chart-outer-wrapper">
              <PieChart
                :chart-data="fundraiserAmountPieChartData"
                :options="pieChartOptions"
                v-if="fundraiserAmountPieChartData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-xl font-bold mt-4 mb-4">One-Time Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Number of One-Time Donations Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Number of One-Time Donations by {{ timeGrouping }}</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="oneTimeDonationsCountData"
                  :options="chartOptions"
                  v-if="oneTimeDonationsCountData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Average One-Time Donation Amount Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">
              Average One-Time Donation Amount by {{ timeGrouping }}
            </h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="avgOneTimeDonationData"
                  :options="chartOptions"
                  v-if="avgOneTimeDonationData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>

          <!-- Total One-Time Donation Sum Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Total One-Time Donation Sum by {{ timeGrouping }}</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="totalOneTimeDonationData"
                  :options="chartOptions"
                  v-if="totalOneTimeDonationData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-xl font-bold mt-4 mb-4">Failed Donations</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Failed Donations Chart -->
          <div class="scrollable-chart-container">
            <h3 class="title font-bold mb-4">Failed Donations by {{ timeGrouping }}</h3>
            <div class="chart-outer-wrapper">
              <div class="chart-inner-wrapper">
                <BarChart
                  :chart-data="failedDonationsData"
                  :options="chartOptions"
                  v-if="failedDonationsData.labels.length > 0"
                />
                <div v-else class="flex items-center justify-center h-full text-gray-500">
                  No data available
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Back Button -->
      <div class="flex justify-end mt-10">
        <RouterLink :to="{ name: 'CampaignList' }" class="btn--table">
          Back to Campaigns
        </RouterLink>
      </div>
    </template>
  </DashboardContainer>
</template>

<script>
import { ref, onBeforeMount, reactive } from 'vue'
import { BarChart, PieChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import DashboardContainer from '../components/DashboardContainer.vue'
import Spinner from '@/components/Spinner'
import { CampaignApi, campaignFunctions } from '@/services/campaign/'
import { fundraiserFunctions } from '@/services/users/'
import { donationFunctions, DonationApi } from '@/services/donations/'
import { WorkedHoursApi } from '@/services/workingHours'
import { useStore } from 'vuex'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// Register Chart.js components
Chart.register(...registerables)

export default {
  name: 'CampaignCharts',
  components: {
    DashboardContainer,
    Spinner,
    BarChart,
    PieChart,
    FlatPickr,
  },
  setup() {
    const store = useStore()
    const isCanvassingOrgAdmin = ref(store.getters.isCanvassingOrgAdmin)
    const isFundraiser = ref(store.getters.isFundraiser)

    const { campaignCollection } = campaignFunctions()
    const campaigns = ref(campaignCollection)
    const { fundraiserCollection } = fundraiserFunctions()
    const fundraisers = ref(fundraiserCollection)
    const loading = ref(false)
    const today = new Date().toISOString().split('T')[0]
    const selectedDate = reactive({
      date1: today,
      date2: '',
    })
    const pageTitle = ref('Campaign Charts')
    const timeGrouping = ref('Day')
    const fundraiserMetrics = ref(null)

    const pieChartOptions = ref({
      responsive: true,
      maintainAspectRatio: false, // This is already set correctly
      aspectRatio: 1, // You can adjust this to change the pie chart's proportions
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || ''
              const value = context.raw || 0
              return `${label}: ${value}`
            },
          },
        },
      },
    })

    // Chart data refs for Canvassing Org Admin
    const numberOfDonationsData = ref({
      labels: [],
      datasets: [
        {
          label: 'Number of Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const donorsOver30Data = ref({
      labels: [],
      datasets: [
        {
          label: 'Donors Over 30',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const averageDonorAgeData = ref({
      labels: [],
      datasets: [
        {
          label: 'Average Donor Age',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const averageDonationAmountData = ref({
      labels: [],
      datasets: [
        {
          label: 'Average Donation Amount',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const donorAgePieChartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        },
      ],
    })

    const donationAmountPieChartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#FF6388',
            '#36A2EJ',
            '#FFCE52',
            '#4BC0C5',
            '4BC0B2',
          ],
        },
      ],
    })

    const fundraiserAgePieChartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        },
      ],
    })

    const fundraiserAmountPieChartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        },
      ],
    })

    const numberOfOneTimeDonationsData = ref({
      labels: [],
      datasets: [
        {
          label: 'Number of One-Time Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const averageOneTimeDonationAmountData = ref({
      labels: [],
      datasets: [
        {
          label: 'Average One-Time Donation Amount',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const totalOneTimeDonationSumData = ref({
      labels: [],
      datasets: [
        {
          label: 'Total One-Time Donation Sum',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const failedDonationsBarChartData = ref({
      labels: [],
      datasets: [
        {
          label: 'Failed Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const shiftsSpentData = ref({
      labels: [],
      datasets: [
        {
          label: 'Shifts Spent',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const numberOfDonorsByTimeData = ref({
      labels: [],
      datasets: [
        {
          label: 'Number of Donors',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
      x_axis_label: 'Hour',
    })

    // Chart data refs for Fundraiser
    const recurringDonationsCountData = ref({
      labels: [],
      datasets: [
        {
          label: 'Recurring Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const fundraiserDonorsOver30Data = ref({
      labels: [],
      datasets: [
        {
          label: 'Donors Over 30',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const oneTimeDonationsCountData = ref({
      labels: [],
      datasets: [
        {
          label: 'One-Time Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const avgOneTimeDonationData = ref({
      labels: [],
      datasets: [
        {
          label: 'Average Amount',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const totalOneTimeDonationData = ref({
      labels: [],
      datasets: [
        {
          label: 'Total Amount',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const failedDonationsData = ref({
      labels: [],
      datasets: [
        {
          label: 'Failed Donations',
          backgroundColor: '#2596be',
          data: [],
        },
      ],
    })

    const campaignMetrics = ref([])

    // Chart options
    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || ''
              const value = context.raw || 0
              return `${label}: ${value}`
            },
          },
        },
      },
    })

    // Calculate time grouping based on date range
    const calculateTimeGrouping = (startDate, endDate) => {
      if (!startDate || !endDate) return 'Day'

      const start = new Date(startDate)
      const end = new Date(endDate)
      const diffTime = Math.abs(end - start)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays > 7 ? 'Week' : 'Day'
    }

    // Fetch data for Canvassing Org Admin
    const fetchCanvassingOrgData = async (startDate, endDate) => {
      console.log(startDate, endDate)
      try {
        loading.value = true
        const campaignMetricsResponse = await DonationApi.csc.get_campaign_performance_metrics({
          startDate,
          endDate,
        })
        console.log(campaignMetricsResponse)

        campaignMetrics.value = campaignMetricsResponse.map((campaign) => ({
          campaign_id: campaign.campaign_id,
          campaign_name: campaign.campaign_name,
          campaign_location: campaign.campaign_location,
          number_of_donations: campaign.recurring_metrics.number_of_donations,
          donors_over_30: campaign.donor_demographics.donors_over_30,
          average_donor_age: campaign.donor_demographics.average_donor_age,
          average_donation_amount: campaign.recurring_metrics.average_donation_amount,
          number_of_one_time_donations: campaign.one_time_metrics.number_of_donations,
          average_one_time_donation_amount: campaign.one_time_metrics.average_donation_amount,
          total_one_time_donation_sum: campaign.one_time_metrics.total_donation_sum,
          number_of_failed_donations: campaign.failed_donations_count,
        }))

        // Update all chart data from consolidated response
        numberOfDonationsData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        numberOfDonationsData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.recurring_metrics.number_of_donations,
        )

        donorsOver30Data.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        donorsOver30Data.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.donor_demographics.donors_over_30,
        )

        averageDonorAgeData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        averageDonorAgeData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.donor_demographics.average_donor_age,
        )

        averageDonationAmountData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        averageDonationAmountData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.recurring_metrics.average_donation_amount,
        )

        numberOfOneTimeDonationsData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        numberOfOneTimeDonationsData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.one_time_metrics.number_of_donations,
        )

        averageOneTimeDonationAmountData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        averageOneTimeDonationAmountData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.one_time_metrics.average_donation_amount,
        )

        totalOneTimeDonationSumData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        totalOneTimeDonationSumData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.one_time_metrics.total_donation_sum,
        )

        failedDonationsBarChartData.value.labels = campaignMetricsResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        failedDonationsBarChartData.value.datasets[0].data = campaignMetricsResponse.map(
          (campaign) => campaign.failed_donations_count,
        )

        // Pie charts
        const donorAgePieChartResponse = await DonationApi.csc.donor_age_pie_chart({
          startDate,
          endDate,
        })
        donorAgePieChartData.value.labels = donorAgePieChartResponse.map((item) => item.label)
        donorAgePieChartData.value.datasets[0].data = donorAgePieChartResponse.map(
          (item) => item.value,
        )

        const donationAmountPieChartResponse = await DonationApi.csc.donation_amount_pie_chart({
          startDate,
          endDate,
        })
        donationAmountPieChartData.value.labels = donationAmountPieChartResponse.map(
          (item) => item.label,
        )
        donationAmountPieChartData.value.datasets[0].data = donationAmountPieChartResponse.map(
          (item) => item.value,
        )

        const numberOfDonorsByTimeResponse = await DonationApi.csc.number_of_donors_by_time({
          startDate,
          endDate,
        })
        numberOfDonorsByTimeData.value = {
          labels: numberOfDonorsByTimeResponse.data.map((item) => item.time_group),
          datasets: [
            {
              label: 'Number of Donors',
              backgroundColor: '#2596be',
              data: numberOfDonorsByTimeResponse.data.map((item) => item.count),
            },
          ],
          x_axis_label: numberOfDonorsByTimeResponse.x_axis_label,
        }

        // Fetch shifts data
        const shiftsSpentResponse = await WorkedHoursApi.csc.shifts_spent_per_campaign({
          startDate,
          endDate,
        })
        shiftsSpentData.value.labels = shiftsSpentResponse.map(
          (campaign) => `${campaign.campaign_name} - ${campaign.campaign_location}`,
        )
        shiftsSpentData.value.datasets[0].data = shiftsSpentResponse.map(
          (campaign) => campaign.total_shifts,
        )

        // Fetch fundraiser count
        const fundraiserCountResponse = await CampaignApi.csc.fundraiser_count_per_campaign({
          startDate,
          endDate,
        })

        // Update campaign metrics with additional data
        campaignMetrics.value = campaignMetrics.value.map((campaign) => {
          const shiftsSpent = shiftsSpentResponse.find(
            (shift) => shift.campaign_id === campaign.campaign_id,
          )
          const fundraiserCount = fundraiserCountResponse.find(
            (f) => f.campaign_id === campaign.campaign_id,
          )

          const perShiftAverage =
            shiftsSpent && shiftsSpent.total_shifts > 0
              ? (campaign.number_of_donations / shiftsSpent.total_shifts).toFixed(2)
              : 0

          return {
            ...campaign,
            fundraiser_count: fundraiserCount ? fundraiserCount.fundraiser_count : 0,
            total_shifts: shiftsSpent ? shiftsSpent.total_shifts : 0,
            per_shift_average: perShiftAverage,
          }
        })
      } catch (error) {
        console.error('Error fetching canvassing org data:', error)
        alert('Failed to fetch canvassing org data')
      } finally {
        loading.value = false
      }
    }

    // Fetch data for Fundraiser
    const fetchFundraiserData = async (startDate, endDate) => {
      try {
        loading.value = true
        const response = await DonationApi.csc.fundraiser_metrics({
          startDate,
          endDate,
        })
        const pieResponse = await DonationApi.csc.donor_analytics_pie_charts_for_fundraisers({
          start_date: startDate,
          end_date: endDate,
        })

        if (pieResponse.age_distribution) {
          fundraiserAgePieChartData.value.labels = pieResponse.age_distribution.map(
            (item) => item.label,
          )
          fundraiserAgePieChartData.value.datasets[0].data = pieResponse.age_distribution.map(
            (item) => item.value,
          )
        }

        if (pieResponse.amount_distribution) {
          fundraiserAmountPieChartData.value.labels = pieResponse.amount_distribution.map(
            (item) => item.label,
          )
          fundraiserAmountPieChartData.value.datasets[0].data = pieResponse.amount_distribution.map(
            (item) => item.value,
          )
        }

        fundraiserMetrics.value = response
        timeGrouping.value = calculateTimeGrouping(startDate, endDate)

        // Update recurring donations data
        recurringDonationsCountData.value = {
          labels: response.recurring_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'Recurring Donations',
              backgroundColor: '#2596be',
              data: response.recurring_donations.by_time.map((item) => item.recurring_count),
            },
          ],
        }

        // Update donors over 30 data
        fundraiserDonorsOver30Data.value = {
          labels: response.recurring_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'Donors Over 30',
              backgroundColor: '#2596be',
              data: response.recurring_donations.by_time.map((item) => item.donors_over_30),
            },
          ],
        }

        // Update one-time donations data
        oneTimeDonationsCountData.value = {
          labels: response.one_time_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'One-Time Donations',
              backgroundColor: '#2596be',
              data: response.one_time_donations.by_time.map((item) => item.one_time_count),
            },
          ],
        }

        // Update average one-time donation data
        avgOneTimeDonationData.value = {
          labels: response.one_time_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'Average Amount',
              backgroundColor: '#2596be',
              data: response.one_time_donations.by_time.map((item) => item.avg_one_time),
            },
          ],
        }

        // Update total one-time donation data
        totalOneTimeDonationData.value = {
          labels: response.one_time_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'Total Amount',
              backgroundColor: '#2596be',
              data: response.one_time_donations.by_time.map((item) => item.total_one_time),
            },
          ],
        }

        // Update failed donations data
        failedDonationsData.value = {
          labels: response.failed_donations.by_time.map((item) => item.time_group),
          datasets: [
            {
              label: 'Failed Donations',
              backgroundColor: '#2596be',
              data: response.failed_donations.by_time.map((item) => item.failed_count),
            },
          ],
        }
      } catch (error) {
        console.error('Error fetching fundraiser data:', error)
        alert('Failed to fetch fundraiser data')
      } finally {
        loading.value = false
      }
    }

    // Handle date change
    const handleDateChange = (dateField, value) => {
      selectedDate[dateField] = value
      if (isCanvassingOrgAdmin.value) {
        fetchCanvassingOrgData(selectedDate.date1, selectedDate.date2)
      }
      if (isFundraiser.value) {
        fetchFundraiserData(selectedDate.date1, selectedDate.date2)
      }
    }

    onBeforeMount(async () => {
      if (isCanvassingOrgAdmin.value) {
        await fetchCanvassingOrgData(selectedDate.date1, selectedDate.date2)
      }
      if (isFundraiser.value) {
        await fetchFundraiserData(selectedDate.date1, selectedDate.date2)
      }
    })

    return {
      loading,
      pageTitle,
      chartOptions,
      pieChartOptions,
      selectedDate,
      handleDateChange,
      isCanvassingOrgAdmin,
      isFundraiser,
      timeGrouping,
      // Canvassing Org Admin data
      numberOfDonationsData,
      donorsOver30Data,
      averageDonorAgeData,
      averageDonationAmountData,
      donorAgePieChartData,
      donationAmountPieChartData,
      numberOfOneTimeDonationsData,
      averageOneTimeDonationAmountData,
      totalOneTimeDonationSumData,
      shiftsSpentData,
      numberOfDonorsByTimeData,
      campaignMetrics,
      failedDonationsBarChartData,
      // Fundraiser data
      recurringDonationsCountData,
      fundraiserDonorsOver30Data,
      oneTimeDonationsCountData,
      avgOneTimeDonationData,
      totalOneTimeDonationData,
      failedDonationsData,
      fundraiserAgePieChartData,
      fundraiserAmountPieChartData,
    }
  },
}
</script>

<style scoped>
.scrollable-chart-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  height: 500px;
  display: flex;
  flex-direction: column;
}
.pie-chart-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.chart-outer-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.chart-inner-wrapper {
  height: 100%;
  min-width: 800px;
}

/* Scrollbar styling */
.chart-outer-wrapper::-webkit-scrollbar {
  height: 8px;
}

.chart-outer-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.chart-outer-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chart-outer-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.title {
  font-size: 1.1rem;
  color: #333;
}
</style>
